import React, { useEffect } from 'react';
import * as Yup from "yup";

import WizardStep from '../WizardStep';
import BootstrapField from '../BootstrapField';


const BirthYear = props => {
	const {
		setValidationSchema,
		values,
		getFieldError,
		onButtonClick,
		goForward,
		setFieldValue
	} = props;

	const validationSchema = Yup.object({
        dob: Yup.object({
        	year: Yup.string()
        	.matches(/^([0-9]{4})?$/, "A Valid Year is required.")
        })
    });

    useEffect(() => {
  		setValidationSchema(validationSchema);
  	}, []);

	return (
		<WizardStep>
			<div className="question row">
				<div className="question__col">
					<h3 className="mb-0">Birth Year</h3>
					<small>(Optional; this is not required for Medicare Advantage and Part D Prescription Drug Plans)</small>
					<div className="input-group">
						<BootstrapField
							type="text"
							name="dob.year"
							className="form-control custom-form-control"
							max="4"
							min="4"
							placeholder="****"
							hasErrors={getFieldError('dob.year')}
						/>
						<div className="input-group-append">
							<button
								type="submit"
								className="btn btn-success"
								onClick={() => onButtonClick('dob.year', values['dob']['year'])}
							>
								Continue
							</button>
						</div>
						<div className="invalid-feedback">{ getFieldError('dob.year') }</div>
					</div>
					<div className="mt-3">
						<button type="button" className="btn btn-link go-back secondary" onClick={() => goForward(values, setFieldValue) }>
							Skip
						</button>
					</div>
				</div>
			</div>
		</WizardStep>
	);
};

export default BirthYear;
