 import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// import '@babel/polyfill';
import ReactDOM from 'react-dom';
import React from 'react';
import thunk from 'redux-thunk';
import axios from 'axios';
// import ReactGA from 'react-ga';
// import ReactPixel from 'react-facebook-pixel';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';

import reducers from './reducers';
import App from './App';
import {SiteContext} from './siteContext';


// const FB_PIXEL_ID = '3268454616519287';

const history = createBrowserHistory();
// const trackingId = "UA-47881347-25";

const baseURL = process.env.NODE_ENV === 'development'
	? '/ajax'
	: 'https://medicare.covercareinsurance.co/ajax';

const axiosInstance = axios.create({
	baseURL
});

const middlewares = [
	thunk.withExtraArgument(axiosInstance),
];

// Log redux store to cosole during development
if (process.env.NODE_ENV === 'development') {
	const { createLogger } =  require('redux-logger');

	const loggerMiddleware = createLogger();

	middlewares.push(loggerMiddleware);
}


// Redux dev tools extension
const composeEnhancers =
	typeof window === 'object' &&
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
		// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
	}) : compose;


const storeEnhancer = composeEnhancers(
	applyMiddleware(...middlewares)
	// other store enhancers if any
);

const store = createStore(
	reducers,
	window.INITIAL_STATE,
	storeEnhancer
);

const rootElement = document.getElementById('root');
const basePath = rootElement.getAttribute('data-base-path');
const publisher = rootElement.getAttribute('data-publisher')
	? rootElement.getAttribute('data-publisher')
	: 'aragon';


// Google Analytics
// ReactGA.initialize(trackingId);

// Facebook Pixel
const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: false, 		// enable logs
};

// ReactPixel.init(FB_PIXEL_ID, {}, options);
// ReactPixel.pageView(); // Facebook Pixel page view
// ReactPixel.fbq('track', 'PageView');


// Initialize google analytics page view tracking and FB
history.listen(location => {
	// ReactPixel.trackSingle(FB_PIXEL_ID, 'PageView');
	// window.obApi('track', 'PAGE_VIEW');
});

ReactDOM.render(
	<Provider store={store}>
		<Router history={history}>
			<ScrollToTop>
				<SiteContext.Provider value={{basePath, publisher}}>
					<App />
				</SiteContext.Provider>
			</ScrollToTop>
		</Router>
	</Provider>
	, rootElement
);
