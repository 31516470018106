import React, { useEffect, Fragment } from "react";
import { connect } from 'react-redux';
import EF from 'EF';
import CSSModules from 'react-css-modules';

import { setEftId, getTcpa } from "../../data/formEntry/actions";
import styles from './styles.scss';



const FirstStepInit = (props) => {
    const {
        setFieldValue,
        setEftId,
        getTcpa
    } = props;

    const initEverflow = async () => {
        const offerId = EF.urlParameter('oid');
        const affiliateId = EF.urlParameter('affid');
        const sub1 = EF.urlParameter('sub1');
        const sub2 = EF.urlParameter('sub2');
        const sub3 = EF.urlParameter('sub3');
        const sub4 = EF.urlParameter('sub4');
        const sub5 = EF.urlParameter('sub5');
        const uid = EF.urlParameter('uid');
        const sourceId = EF.urlParameter('source_id');
        const efTransactionId = EF.urlParameter('_ef_transaction_id');

        let eftId = '';

        setFieldValue('affid', affiliateId);
        setFieldValue('oid', offerId);
        setFieldValue('lp_s1', sub1);
        setFieldValue('lp_s2', sub2);
        setFieldValue('lp_s3', sub3);
        setFieldValue('lp_s4', sub4);
        setFieldValue('lp_s5', sub5);
        setFieldValue('source_id', sourceId);

        await EF.click({
            offer_id: offerId,
            affiliate_id: affiliateId,
            sub1: sub1,
            sub2: sub2,
            sub3: sub3,
            sub4: sub4,
            sub5: sub5,
            uid: uid,
            source_id: sourceId,
            transaction_id: efTransactionId
        }).then(transactionId => {
            setEftId(transactionId);
            eftId = transactionId;
            // console.log(transactionId);
        });

        // console.log(eftId);

        setFieldValue('efTid', eftId);
    };

    useEffect(() => {
        initEverflow();
        getTcpa();
    }, []);

    return (
        <Fragment>
            <div className="row">
                <div styleName="intro">
                    <div styleName="intro__band">
                        <img
                            src="https://covercareinsurance.co/assets/images/circle1.png"
                            alt="Find the right Medicare Plan"
                        />
                        <h3>Find The Right Plan For You!</h3>
                    </div>

                    <p>
                        We connect you with a Licensed Insurance Agent who can help you find the right plan at the
                        right price
                    </p>
                </div>
            </div>
        </Fragment>
    )
};

const mapStateToProps = state => {
    const { formEntry } = state.data;

    return {
        formEntry,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setEftId: efTid => dispatch(setEftId(efTid)),
        getTcpa: () => dispatch(getTcpa())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CSSModules(FirstStepInit, styles));
