import React from 'react';

import WizardStep from '../WizardStep';
import ChoiceButton from '../ChoiceButton';


const Gender = props => {
	const {
		setFieldValue,
		values,
		onButtonClick,
		goForward
	} = props;

	return (
		<WizardStep>
			<div className="question row">
				<div className="question__col">
					<h3 className="mb-0">Gender</h3>
					<small>(Optional; this is not required for Medicare Advantage and Part D Prescription Drug Plans)</small>
					<div className="row">
						<div className="col">
							<ChoiceButton
								values={values}
								className="btn btn-list-select no-width"
								type="submit"
								name="gender"
								value="Female"
								setFieldValue={setFieldValue}
								onButtonClick={onButtonClick}
							>
								Female
							</ChoiceButton>
							<ChoiceButton
								values={values}
								className="btn btn-list-select no-width"
								type="submit"
								name="gender"
								value="Male"
								setFieldValue={setFieldValue}
								onButtonClick={onButtonClick}
							>
								Male
							</ChoiceButton>
							<ChoiceButton
								values={values}
								className="btn btn-list-select no-width"
								type="submit"
								name="gender"
								value="Non-Binary"
								setFieldValue={setFieldValue}
								onButtonClick={onButtonClick}
							>
								Non-Binary
							</ChoiceButton>
							{/*<ChoiceButton*/}
							{/*	values={values}*/}
							{/*	className="btn btn-list-select no-width"*/}
							{/*	type="submit"*/}
							{/*	name="gender"*/}
							{/*	value="Prefer Not to Say"*/}
							{/*	setFieldValue={setFieldValue}*/}
							{/*	onButtonClick={onButtonClick}*/}
							{/*>*/}
							{/*	Prefer Not to Say*/}
							{/*</ChoiceButton>*/}
						</div>
					</div>
					<div>

					</div>
					<div className="mt-3">
						<button type="button" className="btn btn-link go-back secondary" onClick={() => goForward(values, setFieldValue) }>
							Skip
						</button>
					</div>
				</div>
			</div>
		</WizardStep>
	);
};

export default Gender;
