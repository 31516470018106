import baseApi from '../api';

export default class formEntryAPI extends baseApi {
	async createFormEntry(formData, completed) {
		const data = {
			action: 'createFormEntry',
			formData,
			completed
		};

		const response = await this.fetchFromAPI(
			'',
			'create-form-entry',
			'Create Form Entry',
			'post',
			data
		);

		return response;
	}

	async updateFormEntry(entryId, formData, completed) {
		const action = completed ? 'complete' : 'updateFormEntry';

		const data = {
			action,
			id: entryId,
			formData,
			completed
		};

		const response = await this.fetchFromAPI(
			'',
			'update-form-entry',
			'Update Form Entry',
			'post',
			data
		);

		return response;
	}

	async getFormEntry(entryId) {
		const data = {
			action: 'getFormEntry',
			id: entryId
		};

		const response = await this.fetchFromAPI(
			'',
			'get-form-entry',
			'get Form Entry',
			'post',
			data
		);

		return response;
	}

	async getCityState(zipCode) {
		const data = {
			action: 'getCityFromZip',
			zip_code: zipCode
		};

		const response = await this.fetchFromAPI(
			'',
			'get-city-state',
			'get city and state',
			'post',
			data
		);

		return response;
	}

	async getTcpa() {
		const data = {
			action: 'getTcpa'
		};

		const response = await this.fetchFromAPI(
			'',
			'get-tcpa',
			'get tcpa',
			'post',
			data
		);

		return response;
	}

}
